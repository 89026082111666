




































































































































import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Action } from 'vuex-class';
import { NS_ALERTS } from '@/constants/app.constants';
import { Station } from '@/models/station.model';
import { Permissions } from '@/services/permissions.service';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';
import StationForm from '@/components/station/StationForm.component.vue';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';
import StationConfigService from '@/services/station-config.service';

@Component({
  name: 'StationListTable',
  components: { StatusIcon, StationForm, ConfirmationDialog },
})
export default class StationListTable extends Vue {
  @Prop({ default: () => [] }) public stations!: Station[];
  @Prop({ default: true }) public isFiltersOpen!: boolean;
  @Prop({ default: true }) public isLoading!: boolean;
  @Prop({ default: '' }) public selectedStationId!: string;
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public tableHeaders: DataTableHeader[] = [
    { text: 'Health', value: 'healthStatus', width: 100 },
    { text: 'ID', value: 'stationId' },
    { text: 'Last updated On', value: 'updatedAt' },
    { text: 'Last updated By', value: 'updatedBy' },
    { text: 'Name', value: 'stationName' },
    { text: 'Description', value: 'description' },
    { text: 'Main Customer ID', value: 'customerId', width: 150 },
    { text: '', value: 'actions', align: 'end', width: 80 },
  ];
  public selectedStation: Station | null = null;
  public confirmMessage: string = '';
  public confirmType: string = '';
  public confirmDialogOpen: boolean = false;

  public mounted() {
    if (!Permissions.isAdmin()) {
      this.tableHeaders = _(this.tableHeaders)
        .reject((header) => header.value === 'customerId')
        .value();
    }
  }

  public onChartMouseOver(stationId: string) {
    this.$emit('hover', stationId);
  }

  public onChartMouseLeave() {
    this.$emit('hover', null);
  }

  public restartServices(station: Station) {
    this.confirmDialogOpen = true;
    this.confirmType = 'Restart Services';
    this.confirmMessage = `Are you sure you want to ${this.confirmType} on station ${station.stationId}`;
    this.selectedStation = station;
  }

  public restartSSH(station: Station) {
    this.confirmDialogOpen = true;
    this.confirmType = 'Restart SSH';
    this.confirmMessage = `Are you sure you want to ${this.confirmType} on station ${station.stationId}`;
    this.selectedStation = station;
  }

  public reboot(station: Station) {
    this.confirmDialogOpen = true;
    this.confirmType = 'Reboot';
    this.confirmMessage = `Are you sure you want to ${this.confirmType} on station ${station.stationId}`;
    this.selectedStation = station;
  }

  public onCancel() {
    this.selectedStation = null;
    this.confirmType = '';
  }

  public onConfirm() {
    const command = this.confirmType;
    this.getCommandPromise().then(
      () => {
        this.addAlert({
          type: 'success',
          header: `${command} command was run successfully`,
          message: '',
          timeout: 5000,
        });

        this.onCancel();
      },
      (error) => {
        this.addAlert({
          type: 'error',
          header: `There was a problem running the ${command} command`,
          message: error.message,
          timeout: 5000,
        });
      },
    );
  }

  private getCommandPromise(): Promise<unknown> {
    if (!this.selectedStation) {
      return new Promise(() => {});
    }

    switch (this.confirmType) {
      case 'Restart Services':
        return StationConfigService.restartServices(this.selectedStation?.stationId);
      case 'Restart SSH':
        return StationConfigService.restartSSH(this.selectedStation?.stationId);
      case 'Reboot':
        return StationConfigService.reboot(this.selectedStation?.stationId);
      default:
        return new Promise(() => {});
    }
  }
}
