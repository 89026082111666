




import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Station } from '@/models/station.model';
import StationForm from '@/components/station/StationForm.component.vue';
import StationService from '@/services/station.service';

const namespace: string = 'stations';

@Component({
  name: 'StationManagementDetails',
  components: { StationForm },
})
export default class StationManagementDetailsView extends Vue {
  @Prop() public stationId?: string;

  public newStation: Station = new Station();
  public station: Station | null = null;

  public mounted() {
    if (this.stationId) {
      StationService.find(this.stationId).then((station) => {
        this.station = station;
      });
    }
  }
}
