import moment from 'moment-timezone';
import { Node, NodeBasic } from '@/models/node.model';
import { SeismicPosition } from '@/models/position.model';
import { MessageSimple } from '@/models/message.model';
import { StationHealthStatus, StationType } from '@/constants/station.constants';

export interface StationBasic {
  nodes: NodeBasic[];
}

export interface StationReading {
  stationId: string;
  stationName: string;
  stationStatus: string;
  data: number[][];
  statuses: StationReadingStatus[];
  hasDisabledData?: boolean;
  hidden?: boolean;
  waveMessage?: MessageSimple;
  lastPWaveMessage?: MessageSimple;
  lastSWaveMessage?: MessageSimple;
  fullStation: Station;
}

export interface StationReadingStatus {
  from: number;
  to: number;
  y: number;
  enabled: boolean;
  label?: string;
}

export interface StationNotesParams {
  from: number;
  to: number;
  username: string;
  stations: string[];
}

export interface StationNote {
  note: string;
  timestamp: number;
  username: string;
  station: string;
}

export class Station {
  public channelCount: number = 0;
  public clientId: string = '';
  public customerId: string = '';
  public deployedOn: number = moment().valueOf();
  public description: string = '';
  public healthStatus: StationHealthStatus = StationHealthStatus.HEALTHY;
  public healthStatusDetails?: Partial<StationHealth>;
  public location: SeismicPosition = { lat: 0, long: 0, alt: 0 };
  public meta: {
    messagesStompTopic: string;
    readingsMainChannelsStompTopics: string[];
    readingsAllChannelsStompTopics: string[];
    mainNodeId: number;
    mainChannelId: number;
  } = {
    messagesStompTopic: '',
    readingsMainChannelsStompTopics: [],
    readingsAllChannelsStompTopics: [],
    mainNodeId: -1,
    mainChannelId: -1,
  };
  public nodes: Node[] = [];
  public type: StationType = StationType.ARRAY;
  public regionId: string = '';
  public readingConfiguration: {
    // eslint-disable-next-line camelcase
    sample_rate: number;
    // eslint-disable-next-line camelcase
    aggregate_interval: number;
  } = {
    sample_rate: 0,
    aggregate_interval: 0,
  };
  public sensorProvisioning: string = '';
  public sensorType: string = '';
  public stationId: string = '';
  public stationName: string = '';
  public updatedAt: number = moment().valueOf();
  public updatedBy: string = '';
  public parsedUpdatedAtDate?: string = '';
  public parsedUpdatedAtTime?: string = '';
  public parsedDeployedOnDate?: string = '';
  public parsedDeployedOnTime?: string = '';
  public isActive: boolean = false;
  public lastNote?: { note: string };
}

export interface StationSingleHealthType {
  delay: number;
  lastUpdated: number;
  location: SeismicPosition;
  stationId: string;
  status: string;
}

export interface StationHealth {
  lastCallHomeTime: number;
  lastHealthyStatusTime: number;
  lastStatusUpdatedTime: number;
  lastSohMessageReceivedTime: number;
  mqtt: {
    status: string;
    connected: boolean;
    initializing: boolean;
  };
  mseeds: {
    status: string;
    maxMseedsSaved: number;
    mseedDirectory: string;
    fileCount: number;
    newestFileName: string;
    newestFileTime: number;
  };
  nodes: {
    nodes: Node[];
    status: string;
  };
  resources: {
    status: string;
    cpuLoad: number;
    diskFreeSpaceGB: number;
    diskTotalSpaceGB: number;
    diskUsableSpaceGB: number;
    memoryCommittedGB: number;
    memoryInitialGB: number;
    memoryMaxHeap: number;
    memoryUsedHeap: number;
  };
  stationId: string;
  status: string;
}

export interface StationConfigurationBasic {
  id: number;
  type: string;
  appliedAt: number;
  appliedBy: string;
  becameActiveAt: number;
  becameUnusedAt: number;
  failedAt: number;
  status: string;
  stationId: string;
  description: string;
  service: string;
}

export interface StationConfiguration extends StationConfigurationBasic {
  oldConfigFile: StationConfigurationFile;
  newConfigFile: StationConfigurationFile;
  oldVersion: StationConfigurationVersion;
  newVersion: StationConfigurationVersion;
  newConfig: any;
  oldConfig: any;
  parsed?: {
    appliedAt: string;
  };
}

export interface ServiceVersion {
  id: number;
  service: string;
  createdAt: number;
  createdBy: string;
  version: string;
  gitCommit: string;
  description: string;
  appliedOn: StationConfigurationBasic[];
  parsed?: {
    createdAt: string;
  };
}

export interface ServiceVersionApply {
  stationServiceVersionId: number;
  stationIds: string[];
  description: string;
  version?: string;
}

export interface StationConfigurationFileApply {
  stationConfigFileId: number;
  stationId?: string;
  description: string;
  version?: string;
  contents?: string;
}

export interface StationConfigurationVersion {
  id: number;
  service: string;
  createdAt: number;
  createdBy: string;
  version: string;
  gitCommit: string;
  description: string;
}

export interface StationConfigurationFile {
  id: number;
  stationId: string;
  service: string;
  createdAt: number;
  createdBy: string;
  version: string;
  gitCommit: string;
  fileName: string;
  hash: string;
  contentEncoding: string;
  contents: string;
  description: string;
  status: string;
  appliedOn: StationConfigurationBasic[];
}
