var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-10",class:_vm.isFiltersOpen ? 'station-list-table' : 'station-list-table--tight',attrs:{"headers":_vm.tableHeaders,"items":_vm.stations,"items-per-page":-1,"item-key":"stationId","loading":_vm.isLoading,"single-expand":true,"sort-by":['updatedAt'],"sort-desc":true,"fixed-header":"","show-expand":""},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('StationForm',{attrs:{"station":item,"is-edit":true}})],1)]}},{key:"item.stationId",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },attrs:{"id":("station-table-" + (item.stationId))},on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(_vm._s(item.stationId))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(_vm._s(_vm._f("getDateTime")(item.updatedAt)))])]}},{key:"item.updatedBy",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(_vm._s(item.updatedBy))])]}},{key:"item.stationName",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(_vm._s(item.stationName))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(_vm._s(item.description))])]}},{key:"item.customerId",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(" "+_vm._s(item.customerId !== 'null' ? item.customerId : 'N/A')+" ")])]}},{key:"item.healthStatus",fn:function(ref){
var item = ref.item;
return [_c('StatusIcon',{attrs:{"status":item.healthStatus},on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('section',{staticClass:"table__actions",staticStyle:{"min-width":"80px"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
var attrsTooltip = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","icon":"","link":""}},'v-btn',Object.assign({}, attrs, attrsTooltip),false),Object.assign({}, on, onTooltip)),[_c('v-icon',[_vm._v("mdi-cog-clockwise")])],1)]}}],null,true)},[_c('span',[_vm._v("Station Settings")])])]}}],null,true)},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.restartServices(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-database")])],1),_c('v-list-item-title',[_vm._v("Restart Services")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.restartSSH(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-ssh")])],1),_c('v-list-item-title',[_vm._v("Restart SSH")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.reboot(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-restart")])],1),_c('v-list-item-title',[_vm._v("Reboot")])],1)],1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","icon":"","link":"","to":{ name: 'stations-edit', params: { stationId: item.stationId } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1)]}}])}),_c('ConfirmationDialog',{attrs:{"open":_vm.confirmDialogOpen,"message":_vm.confirmMessage},on:{"update:open":function($event){_vm.confirmDialogOpen=$event},"onConfirm":_vm.onConfirm,"onCancel":_vm.onCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }