





























import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { NS_STATIONS } from '@/constants/app.constants';
import { Customer } from '@/models/customer.model';
import { FiltersStationList } from '@/models/states/filters-state.model';
import { QueryService } from '@/services/query.service';
import CustomerSelect from '@/components/shared/CustomerSelect.component.vue';
import { Permissions } from '@/services/permissions.service';

const now = moment();

@Component({
  name: 'OptionsStationList',
  components: {
    CustomerSelect,
  },
})
export default class OptionsStationList extends Vue {
  @Prop({ default: true }) public isOpen!: number;
  @Getter('getCustomers', { namespace: NS_STATIONS }) customers?: Customer[];

  public isAdmin: boolean = Permissions.isAdmin();
  public isOpenInner: number | undefined = 0;
  public filters: Partial<FiltersStationList> = {
    customers: null,
    textSearch: '',
  };

  public openFilters: number = 0;

  public mounted() {
    this.applyUrlParams();
  }

  @Emit()
  public onFiltersChanged(): Partial<FiltersStationList> {
    return this.filters;
  }

  @Watch('isOpen')
  public onOpenChange() {
    this.isOpenInner = this.isOpen ? 0 : undefined;
  }

  @Watch('$route')
  public onRouteChange() {
    this.applyUrlParams();
  }

  public applyFilters() {
    this.onFiltersChanged();
    this.updateQueryParams();
  }

  public updateQueryParams() {
    const newQueryParams = _.omit(this.filters, ['date', 'time']);
    const queryParams = _.omit(this.getQueryParams(), ['date', 'time']);

    if (_.isEqual(newQueryParams, queryParams)) {
      return;
    }

    this.$router
      .push(
        _.extend({}, this.$route, {
          query: QueryService.convertQueryParamsToString(newQueryParams),
        }),
      )
      .catch(() => {});
  }

  private applyUrlParams() {
    if (!_.isEmpty(this.$route.query)) {
      this.filters = this.getQueryParams();
    }

    this.applyFilters();
  }

  private getQueryParams(): Partial<FiltersStationList> {
    if (!_.isEmpty(this.$route.query)) {
      return {
        customers: QueryService.getCustomers(this.$route),
        textSearch: (this.$route.query.textSearch as string) || null,
      };
    }
    return {};
  }
}
