


























import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { NS_STATIONS } from '@/constants/app.constants';
import { Station } from '@/models/station.model';
import { FiltersStationList } from '@/models/states/filters-state.model';
import StationService from '@/services/station.service';
import OptionsStationList from '@/components/options-panels/OptionsStationList.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import StationCard from '@/components/station/StationCard.component.vue';
import StationListTable from '@/components/station/StationListTable.component.vue';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';

@Component({
  name: 'StationManagementView',
  components: {
    OptionsStationList,
    RealTimeMap,
    StationCard,
    StatusIcon,
    StationListTable,
  },
})
export default class StationManagementView extends Vue {
  @Getter('getAllStations', { namespace: NS_STATIONS }) vuexStations?: Station[];

  public stations: Station[] = [];
  public hoverOverStationId: string | null = null;
  public isFiltersOpen: boolean = true;
  public isLoading: boolean = true;
  public filters: FiltersStationList | undefined = undefined;
  public selectedStationId: string | null = null;

  @Watch('vuexStations')
  public onStationChange() {
    this.loadStations(this.filters);
  }

  public onFiltersChanged(filters: FiltersStationList) {
    this.filters = filters;
    this.loadStations(filters);
  }

  public onHover(stationId: string | null) {
    this.hoverOverStationId = stationId;
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  public loadStations(filters: FiltersStationList | undefined = undefined) {
    this.isLoading = true;
    StationService.query(filters, false).then(
      (response) => {
        this.isLoading = false;
        this.stations = response.stations;
      },
      () => {
        this.isLoading = false;
        this.stations = [];
      },
    );
  }

  public scrollToStation(stationId: string) {
    const scrollPosition: number = document.getElementById(`station-table-${stationId}`)?.getBoundingClientRect().top ?? 0;
    document.getElementsByClassName('v-data-table__wrapper')[0].scrollTo(0, scrollPosition - 400);

    this.selectedStationId = stationId;
  }
}
